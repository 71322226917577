const BASE_URL = 'https://api.talentiv.id'
const endpoint = {
  checkServer: `${BASE_URL}`,
  general: `${BASE_URL}/v1/api`,
  auth: {
    changePassword: `${BASE_URL}/v1/api/change-password`,
    forgotPassword: `${BASE_URL}/v1/api/reset-password`,
    login: `${BASE_URL}/v1/api/login`,
    register: `${BASE_URL}/v1/api/register`
  },
  testForm: {
    getAllQuestion: `${BASE_URL}/v1/api/question/test-form`,
    getAllTestForm: `${BASE_URL}/v1/api/test-form-per-user`,
    recordTestForm: `${BASE_URL}/v1/api/user-summary`,
    saveAnswer: `${BASE_URL}/v1/api/record-answer-participant`,
    saveMultipleAnswer: `${BASE_URL}/v1/api/record-multiple-answer-participant`,
    upload: `${BASE_URL}/v1/api/upload-file`
  }
}

export default endpoint
